import * as React from "react";
import "./styles.css";
import Term from "./main.js";
import download from "./download";

import { coverLetter } from "./statics";

export default function App() {
  const files = [
    {
      name: "resume.pdf",
      type: "file",
      size: 1000,
      date: "22 Jul 03:30",
      user: "dimaslz",
      group: "staff",
      x: "",
      permission: "drwxr-xr-x",
      content: (): string => {
        download('./resume.pdf', 'dimas-lopez-zurita-resume.pdf');
        return "downloading..."
      },
    },
    {
      name: "cover-letter.txt",
      type: "txt",
      size: 1000,
      date: "22 Jul 03:30",
      user: "dimaslz",
      group: "staff",
      x: "",
      permission: "drwxr-xr-x",
      content: coverLetter
    }
  ];
  React.useEffect(() => {
    new Term({
      el: document.getElementById("term"),
      cwd: "<span style='font-weight: bold;'>guest@dimaslz.dev:~/</span>",
      initialMessage: `Welcome to dimaslz.dev!, this is my geek site just for fun built in React and TypeScript.
  NOTE: This this only an terminal emulation, for that, you will not have a lot common commands.
  Type <b>help</b> to start\n\r`,
      cmd: function(argv: any, argc: any) {
        console.log(argv, argc);
        return false;
      },
      files,
      cmds: {
        ls: (argv: any) => {
          var args = [...argv];
          args.shift();

          let documents: any[] = [...files];
          if (/^(-l.*|-.+l)/g.test(args[0])) {
            if (/^(-a.*|-.+a)/g.test(args[0])) {
              documents.unshift({
                name: "..",
                permission: "drwxr-xr-x",
                user: "dimaslz",
                group: "dimaslz",
                date: "22 Jul 03:30",
                size: "999"
              });
              documents.unshift({
                name: ".",
                permission: "drwxr-xr-x",
                user: "dimaslz",
                group: "dimaslz",
                date: "22 Jul 03:30",
                size: "999"
              });
            }
            return `<table border='0' style="display: flex;">
            <tbody style="display: inline-table">
            ${documents
              .map(
                doc =>
                  "<tr>" +
                  "<td style='padding: 2px 8px;'>" +
                  doc.permission +
                  "</td>" +
                  "<td style='padding: 2px 8px;'>" +
                  doc.user +
                  "</td>" +
                  "<td style='padding: 2px 8px;'>" +
                  doc.group +
                  "</td>" +
                  "<td style='padding: 2px 8px;'>" +
                  doc.size +
                  "</td>" +
                  "<td style='padding: 2px 8px;'>" +
                  doc.date +
                  "</td>" +
                  "<td style='padding: 2px 8px;'>" +
                  doc.name +
                  "</td>" +
                  "</tr>"
              )
              .join("")}
            </tbody>
            </table>
            `;
          } else {
            documents = documents.map(doc => doc.name);
            if (/^(-a.*|-.+a)/g.test(args[0])) {
              documents.unshift("..");
              documents.unshift(".");
            }

            return documents.join(" ");
          }
        },
        cat: (argv: any, argc: any) => {
          if (argc === 2) {
            const filename = argv.pop();
            const fileContent = files.find(file => file.name === filename);
            if (fileContent) {
              if (typeof fileContent.content === 'function') {
                return fileContent.content();
              }

              return fileContent.content;
            }

            return (
              "cat: {white}{bold}" +
              filename +
              "{/bold}{/white}: No such file or directory"
            );
          }

          return files.map(file => file.name).join(" ");
        },
        help: () => {
          return `Usage: [cmd] [file]
    example: ls
    example: cat cover-letter.txt
    
    Available commands:
      - ls        get a simple list of documents
      - ls -al    TODO
      - cat       get the content of the file
      `;
        }
      }
    });
  });
  return <div className="term" id="term" />;
}
