function Term(config) {
  var term = config.el || document.getElementById("term");
  var termBuffer = config.initialMessage || "";
  var lineBuffer = config.initialLine || "";
  this.lineBuffer = lineBuffer;
  var cwd = config.cwd || "~/";
  var files = config.files || [];
  var tags = config.tags || ["red", "blue", "white", "bold"];
  var processCommand = config.cmd || false;
  var maxBufferLength = config.maxBufferLength || 8192;
  var commandHistory = [];
  var currentCommandIndex = -1;
  var maxCommandHistory = config.maxCommandHistory || 100;
  var autoFocus = config.autoFocus || false;
  var coreCmds = {
    clear: clear,
    ...config.cmds
  };
  var filesPredicted = [];
  var filesPredictedIndex = 0;

  var input = document.createElement("textarea");
  input.className = "term-input";
  document.body.appendChild(input);
  if (autoFocus) {
    input.focus();
  }

  /*
  setTimeout(() => {
    const content = document.querySelector("#term .content");
    input = content;
    
  });
  */

  function getLeader() {
    return cwd + "$ ";
  }

  function renderTerm() {
    var bell = '<span class="bell"></span>';

    var ob =
      termBuffer +
      getLeader() +
      `<span class="content" contenteditable>${lineBuffer}</span>`;
    //var ob = termBuffer + getLeader() + lineBuffer;
    term.innerHTML = ob;
    term.innerHTML += bell;
    term.scrollTop = term.scrollHeight;
  }

  function writeToBuffer(str) {
    termBuffer += str;

    //Stop the buffer getting massive.
    if (termBuffer.length > maxBufferLength) {
      var diff = termBuffer.length - maxBufferLength;
      termBuffer = termBuffer.substr(diff);
    }
  }

  function renderStdOut(str) {
    var i = 0,
      max = tags.length;
    for (i; i < max; i++) {
      var start = new RegExp("{" + tags[i] + "}", "g");
      var end = new RegExp("{/" + tags[i] + "}", "g");
      str = str.replace(start, '<span class="' + tags[i] + '">');
      str = str.replace(end, "</span>");
    }
    return str ? `${str}\n\r` : str;
  }

  function clear(argv, argc) {
    termBuffer = "";
    return "";
  }

  function isCoreCommand(line) {
    if (coreCmds.hasOwnProperty(line)) {
      return true;
    }
    return false;
  }

  function coreCommand(argv, argc) {
    var cmd = argv[0];
    return coreCmds[cmd](argv, argc);
  }

  function processLine(output = true) {
    //Dispatch command
    var stdout,
      line = lineBuffer,
      argv = line.split(" "),
      argc = argv.length;

    var cmd = argv[0];

    lineBuffer += "\n";
    writeToBuffer(getLeader() + lineBuffer);
    lineBuffer = "";

    //If it's not a blank line.
    if (cmd !== "") {
      //If the command is not registered by the core.
      if (!isCoreCommand(cmd)) {
        //User registered command
        if (processCommand) {
          stdout = processCommand(argv, argc);
        } else {
          stdout =
            "{white}{bold}" + cmd + "{/bold}{/white}: command not found\n";
        }
      } else {
        //Execute a core command
        stdout = coreCommand(argv, argc);
      }

      //If an actual command happened.
      if (stdout === false) {
        stdout = "{white}{bold}" + cmd + "{/bold}{/white}: command not found\n";
      }

      if (output) {
        stdout = renderStdOut(stdout);
        writeToBuffer(stdout);

        addLineToHistory(line);
      }
    }

    renderTerm();
  }

  function addLineToHistory(line) {
    commandHistory.unshift(line);
    currentCommandIndex = -1;
    if (commandHistory.length > maxCommandHistory) {
      console.log("reducing command history size");
      console.log(commandHistory.length);
      var diff = commandHistory.length - maxCommandHistory;
      commandHistory.splice(commandHistory.length - 1, diff);
      console.log(commandHistory.length);
    }
  }

  function isInputKey(keyCode) {
    var inputKeyMap = [
      32,
      190,
      192,
      189,
      187,
      220,
      221,
      219,
      222,
      186,
      188,
      191
    ];
    if (inputKeyMap.indexOf(keyCode) > -1) {
      return true;
    }
    return false;
  }

  function toggleCommandHistory(direction) {
    var newIndex = currentCommandIndex + direction;

    if (newIndex < -1) newIndex = -1;
    if (newIndex >= commandHistory.length) newIndex = commandHistory.length - 1;

    if (newIndex !== currentCommandIndex) {
      currentCommandIndex = newIndex;
    }

    if (newIndex > -1) {
      //Change line to something from history.
      lineBuffer = commandHistory[newIndex];
    } else {
      //Blank line...
      lineBuffer = "";
    }
  }

  function acceptInput(e) {
    e.preventDefault();

    if ((e.keyCode >= 48 && e.keyCode <= 90) || isInputKey(e.keyCode)) {
      if (!e.ctrlKey) {
        //Character input
        lineBuffer += e.key;
      } else {
        if (e.ctrlKey && e.key === "c") {
          processLine(false);
        }
        if (false && e.key === "v") {
          let paste = (e.clipboardData || window.clipboardData).getData("text");
          paste = paste.toUpperCase();
          console.log("AAAA", paste);
        }
        //Hot key input? I.e Ctrl+C
      }
    } else if (e.keyCode === 13) {
      processLine();
    } else if (e.keyCode === 9) {
      //lineBuffer += "\t";
      lineBuffer = onTab();
    } else if (e.keyCode === 38) {
      toggleCommandHistory(1);
    } else if (e.keyCode === 39 || e.keyCode === 37) {
      // TODO: Move caret position
    } else if (e.keyCode === 40) {
      toggleCommandHistory(-1);
    } else if (e.key === "Backspace") {
      lineBuffer = lineBuffer.substr(0, lineBuffer.length - 1);
    }

    filesPredicted = [];
    filesPredictedIndex = 0;
    input.value = lineBuffer;
    renderTerm();
  }

  function onTab() {
    const line = lineBuffer,
      argv = line.split(" ");
    if (argv.length === 1) {
      return predictCMD();
    }

    return predictFile() || "";
  }

  function predictCMD() {
    const line = lineBuffer,
      argv = line.split(" ");
    const cmd = argv.shift();
    const availablesCmds = Object.keys(coreCmds);
    const possibleCmds = availablesCmds.filter(c =>
      new RegExp(`^${cmd}`).test(c)
    );

    return possibleCmds.shift() || "";
  }

  function predictFile() {
    if (!filesPredicted.length) {
      const line = lineBuffer,
        argv = line.split(" ");
      const cmd = argv.shift();
      const fileToPredict = argv[0];
      const lineCleaned = line.replace(`${cmd} ${fileToPredict}`, `${cmd} `);
      filesPredicted = files.map(file => file.name);
      const match = filesPredicted.filter(fileName =>
        new RegExp(`^${fileToPredict}`, "g").test(fileName)
      );

      const fileToPrint = match[filesPredictedIndex];
      filesPredicted = filesPredicted.map(file => fileToPrint).filter(i => i);

      filesPredictedIndex++;
      if (fileToPrint === undefined) {
        return lineCleaned;
      }

      return lineCleaned + fileToPrint;
    }

    return lineBuffer;
  }

  term.addEventListener("click", function(e) {
    input.focus();
    term.classList.add("term-focus");
  });
  input.addEventListener("keydown", acceptInput);
  input.addEventListener("blur", function(e) {
    term.classList.remove("term-focus");
  });
  input.addEventListener("paste", event => {
    let paste = (event.clipboardData || window.clipboardData).getData("text");
    paste = paste.toUpperCase();

    const selection = window.getSelection();
    if (!selection.rangeCount) return false;
    selection.deleteFromDocument();
    selection.getRangeAt(0).insertNode(document.createTextNode(paste));

    event.preventDefault();
  });
  renderTerm();
}

export default Term;
/*
var myTerm = new Term({
  el: document.getElementById("term"),
  cwd: "guest@dam.pe:/",
  initialMessage: "Welcome to dam.pe!\n"
  tags: ['red', 'blue', 'white', 'bold'],
  maxBufferLength: 8192,
  maxCommandHistory: 500,
  cmd: function(argv, argc) {
    console.log(argv);
    return false;
  }
});*/
/*var mySecondTerm = new Term({
  el: document.getElementById("term2"),
  cwd: "guest@dam.pe:/",
  initialMessage: "Welcome to dam.pe! 2\n"

  autoFocus: false,
  tags: ['red', 'blue', 'white', 'bold'],
  maxBufferLength: 8192,
  maxCommandHistory: 500,
  cmd: function(argv, argc) {
    console.log(argv);
    return false;
  }
});*/
