export const coverLetter = `I am a technology geek, with over 10 years of experience as a FullStack Software Developer.

Enthusiast and proactive, I have an entrepreneurial mindset, always involved in some side projects in my spare time to keep learning and discover new challenges. 

Skilled in multiple technologies and platforms (backend, infrastructure, databases, frontend, android, ios, desktop), I am able to adapt to the team's needs. 

As an employee, I am emphatic and mindful of the product as a whole. I like to understand the business model and team's goals, to be more productive, and proactively think something about what could be a benefit for the user or project. I like to learn, work, and contribute with my code and my positive, supportive attitude. I like to mentor when is needed just like I am happy to be mentored as well.

Professionally, I am more involved in the JavaScript stack, working the top frameworks in frontend and NodeJS or Go for the backend.

---

Being the leader has been a rewarding experience, I would love to have the opportunity to contribute to the team in this role.`
